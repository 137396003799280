import React, { Component } from 'react'
import AppContainer from './Containers/AppContainer'

class App extends Component {
  render(){
    return (
      <AppContainer />
    )
  }
}

export default App;
